const config = {
    backendUrl_PROD: "https://support-srv.club-dreamservices.com",
    backendUrl_DEV: "https://support-srv.club-dreamservices.com",
    frontURL_DEV: "https://support.club-dreamservices.com/",
    frontURL_PROD: "https://support.club-dreamservices.com/",
    secret: "b65fc7f7b187f2c94fc47c7bcd46b3d8",
    resendApiKey: "re_LpsQ3PyW_GYhKELecTn3G15AAiw2HEej7",
    fromEmail: "DreamServices Support <contact@club-dreamservices.com>",
    fromName: "DreamServices Support",
    env: "production",
    unamiSiteKey: "fa5e203b-890f-454d-b0a2-dc7819d226fa",
    //env: "development"
};

export default config;
/* eslint-disable react/prop-types */
import { useState, useRef, useEffect } from "react";
import {
    PaperAirplaneIcon,
    PhotoIcon,
    XMarkIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { AnimatePresence } from "framer-motion";
import ManageModal from "./ManageModal";
import axios from "axios";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import {getImageName, handleUmamiEvent, isImageUrl} from "@/helpers/functions.js";
import {socket} from "../socket.js";

export default function MessageModal({
  isModalOpen,
  selectedConversation,
  isClosing,
  messages,
// eslint-disable-next-line react/prop-types
  currentUser,
  message,
  setMessage,
  closeModal,
  handleFileChange,
  file,
  setFile,
  setMessages,
}) {
  const [activeModalIndex, setActiveModalIndex] = useState(null);
  const imageModalRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const modalRef = useRef(null);
  const messagesContainerRef = useRef(null);
  useEffect(() => {
    if (isModalOpen) {
      scrollToBottom();
    }
  }, [isModalOpen]);

  useEffect(() => {
    scrollToBottom();
  }, [selectedConversation?.messages]);

    useEffect(() => {
            if(selectedConversation) {
                    socket.connect();
                    socket.emit("joinConversation", selectedConversation?._id);
                    socket.on("newMessage", (message) => {
                            if(message.conversation === selectedConversation?._id && message.sender !== currentUser?._id) {
                                    // si le nouveau mesage entrant est bien dans la conversation actuelle
                                    // et que le message n'est pas envoyé par l'utilisateur actuel
                                    // alors on l'ajoute à la liste des messages
                                    setMessages((prevMessages) => [...prevMessages, message]);
                                    scrollToBottom();
                            }
                    });
                    return () => {
                            socket.off("newMessage");
                    }
            }
    }, [selectedConversation]);

        const emitMessage = (message) => {
                socket.emit("sendMessage", {
                        conversationId: selectedConversation?._id,
                        message: message
                });
        }

    const handleSendMessage = () => {
        if ((message.trim() || file) && selectedConversation) {
            const newMessage = {
                sender: currentUser._id,
                //type: file ? "image" : "text",
                conversation: selectedConversation._id,
                isFile: !!file,
                [file ? "file" : "message"]: file || message,
            };

            if(file) {
                const fd = new FormData();
                fd.append("file", file);
                    // eslint-disable-next-line react/prop-types
                fd.append("sender", currentUser._id);
                fd.append("conversation", selectedConversation._id);
                fd.append("isFile", true);

                axios.post(`/messages/${selectedConversation._id}/upload-file`, fd)
                    .then((response) => {
                        setMessages([...messages, response.data.message]);
                        emitMessage(response.data.message);
                    })
                    .catch(() => {
                        toast.error("Erreur lors de l'envoi du message. votre fichier ne doit pas dépasser 2mb");
                    })
            } else {
                axios.post(`/messages/${selectedConversation._id}`, newMessage)
                    .then((response) => {
                        setMessages([...messages, response.data]);
                        emitMessage(response.data);
                    })
                    .catch(() => {
                        toast.error("Erreur lors de l'envoi du message.");
                    })
            }
                handleUmamiEvent("sendMessage", {
                        sender: currentUser._id,
                        conversation: selectedConversation._id,
                        isFile: !!file
                });
      setMessage("");
      setFile(null);
    }
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        if (selectedImage) {
          closeImageModal();
        }
        // Fermer la modal principale si ouverte
        else if (isModalOpen) {
          closeModal();
        }
      }
    };

    const handleClickOutside = (e) => {
      // Si une image modal est ouverte
      if (selectedImage) {
        if (
          imageModalRef.current &&
          !imageModalRef.current.contains(e.target)
        ) {
          closeImageModal();
        }
        return; // Arrêter ici si c'est une modal d'image
      }

      // Sinon, fermer la modal principale
      if (
        isModalOpen &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen, selectedImage]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isModalOpen]);

  const closeImageModal = () => {
    setSelectedImage(null);
  };
  const openImageModal = (url) => {
    setSelectedImage(url);
  };
  return (
    <>
      <AnimatePresence>
        {/* Modal de message */}
        {isModalOpen && selectedConversation && (
          <div className="fixed inset-0 z-50 flex items-end bg-black bg-opacity-25 backdrop-blur-sm justify-center">
            <div
              ref={modalRef}
              className={`w-full max-w-7xl h-[80%] bg-white rounded-t-3xl shadow-lg relative ${
                isClosing ? "animate-slide-out-down" : "animate-slide-in-up"
              }`}
            >
              <header className="flex items-center justify-between px-6 py-4 border-b border-gray-200">
                <h3 className="text-xl font-bold text-black">
                  {selectedConversation.ticket.ticketTitle}
                </h3>
                <button
                  className="p-2 rounded-full text-primary hover:text-accent duration-300 transition"
                  onClick={closeModal}
                >
                  <XMarkIcon className="w-6 h-6" />
                </button>
              </header>
              <div
                className="flex-1 overflow-y-auto p-6 space-y-6"
                style={{
                  maxHeight: "calc(100% - 150px)",
                }}
                ref={messagesContainerRef}
              >
                {messages.map((msg, index) => (
                  <div
                    key={index}
                    className={`relative flex flex-col ${
                      msg.sender === currentUser._id
                        ? "items-end"
                        : "items-start"
                    } space-y-1`}
                  >
                    {/* Message texte */}
                    {msg.message && !msg.isFile && (
                      <div
                        className={`p-4 rounded-xl max-w-[70%] relative group ${
                          msg.sender === currentUser._id
                            ? "bg-primary text-white"
                            : "bg-gray-100 text-gray-800"
                        }`}
                      >
                        <p className="text-sm">{msg.message}</p>
                        {/* Bouton d'ouverture de modal - uniquement pour vos messages */}
                        {msg.sender === currentUser._id && (
                          <div
                            onClick={() =>
                              setActiveModalIndex(
                                activeModalIndex === index ? null : index
                              )
                            }
                            className={`absolute top-[-12px] left-[-12px] w-6 h-6 bg-gray-200 hover:bg-gray-400 transition shadow-xl rounded-full opacity-0 group-hover:opacity-100 duration-300 flex items-center justify-center cursor-pointer`}
                          >
                            <AdjustmentsHorizontalIcon className="w-4 h-4 text-primary" />
                          </div>
                        )}
                      </div>
                    )}

                    {/* Message avec fichier */}
                    {msg.isFile &&
                      (isImageUrl(msg.message) ? (
                        <img
                          src={msg.message}
                          alt="Image envoyée"
                          className="rounded-lg object-cover w-[200px] h-auto cursor-pointer"
                          onClick={() => openImageModal(msg.message)}
                        />
                      ) : (
                        <a
                          href={msg.message}
                          target="_blank"
                          className="inline-block px-6 py-3 text-white bg-accent rounded-lg shadow-md hover:bg-accentHover hover:shadow-lg focus:ring-4 focus:ring-purple-400 focus:outline-none active:bg-purple-800 transition duration-300 ease-in-out"
                        >
                          Télécharger le fichier: {getImageName(msg.message)}
                        </a>
                      ))}

                    {/* Date et heure */}
                    <span className="text-xs text-gray-400">
                      {dayjs(msg.createdAt).format("DD/MM hh:mm")}
                    </span>

                    {/* Affichage conditionnel de ManageModal */}
                    {activeModalIndex === index && (
                      <ManageModal
                        closeModal={() => setActiveModalIndex(null)}
                        style={
                          index === 0
                            ? {
                                position: "absolute",
                                top: 50,
                                right: 60,
                              }
                            : {
                                position: "absolute",
                                bottom: 90,
                                right: 60,
                              }
                        }
                        selectedMessage={msg.message}
                        messages={messages}
                        setMessages={setMessages}
                        setMessage={setMessage}
                      />
                    )}
                  </div>
                ))}
              </div>
              <footer className="absolute bottom-0 w-full px-6 py-4 border-t border-gray-200 bg-white flex items-center gap-4">
                {/* Bouton pour ajouter un fichier */}
              {selectedConversation.ticket.ticketStatus !== 'closed' ? (
                  <label className="p-2 rounded-full text-gray-500 hover:text-primary hover:bg-gray-100 cursor-pointer transition duration-300">
                          <PhotoIcon className="w-6 h-6" />
                          <input
                              type="file"
                              accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt"
                              className="hidden"
                              onChange={handleFileChange}
                          />
                  </label>
              ) : null}
                {/* Champ d'entrée ou affichage de fichier */}
                {!file && selectedConversation.ticket.ticketStatus !== 'closed' ? (
                  <div className="flex items-center w-full">
                    <input
                      type="text"
                      className="flex-1 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:outline-none transition duration-300"
                      placeholder="Écrivez votre message..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleSendMessage();
                        }
                      }}
                    />
                    {(message.trim() || file) && (
                      <button
                        className="ml-2 p-2 text-primary hover:text-white hover:bg-primary transition duration-300 rounded-full"
                        onClick={handleSendMessage}
                      >
                        <PaperAirplaneIcon className="w-6 h-6" />
                      </button>
                    )}
                  </div>
                ) : file && selectedConversation.ticket.ticketStatus !== 'closed' ? (
                  <div className="flex items-center w-full">
                    <p className="flex-1 px-4 py-2 text-sm text-gray-700 truncate">
                      Envoyer le fichier : {file.name}
                    </p>
                    <button
                      className="ml-2 p-2 text-primary hover:text-white hover:bg-primary transition duration-300 rounded-full"
                      onClick={handleSendMessage}
                    >
                      <PaperAirplaneIcon className="w-6 h-6" />
                    </button>
                  </div>
                ) : <p className={"text-red-500"}>Le ticket à été fermée il vous ai impossible d&apos;y ajouter d&apos;autre message pendant que celui-ci est fermé, si vous souhaiter le rouvrir merci de bien vouloir contacter l&apos;équipe de support Dream Services.</p>}
              </footer>
            </div>
            {/* Modal d'image */}
            {selectedImage && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                <div
                  ref={imageModalRef}
                  className="relative bg-white rounded-xl animate-grow"
                  style={{
                    maxWidth: "90vw",
                    maxHeight: "90vh",
                  }}
                >
                  <button
                    className="absolute top-4 right-4 p-2 text-primary hover:text-accent duration-300 transition"
                    onClick={closeImageModal}
                  >
                    <XMarkIcon className="w-6 h-6" />
                  </button>
                  <img
                    src={selectedImage}
                    alt="Image en grand format"
                    className="rounded-lg object-cover"
                    style={{
                      width: "100%",
                      height: "auto",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                    onLoad={(e) => {
                      const { naturalWidth, naturalHeight } = e.target;
                      const ratio = Math.min(
                        (0.9 * window.innerWidth) / naturalWidth,
                        (0.9 * window.innerHeight) / naturalHeight
                      );
                      e.target.style.width = `${naturalWidth * ratio}px`;
                      e.target.style.height = `${naturalHeight * ratio}px`;
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </AnimatePresence>
    </>
  );
}

MessageModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  selectedConversation: PropTypes.object,
  isClosing: PropTypes.bool.isRequired,
  messages: PropTypes.array.isRequired,
  message: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  openImageModal: PropTypes.func,
  handleFileChange: PropTypes.func.isRequired,
  file: PropTypes.object,
  setMessages: PropTypes.func.isRequired,
  setFile: PropTypes.func.isRequired,
};

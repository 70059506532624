import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export default function Footer() {
  return (
    <motion.footer
      className="w-full py-12 bg-white border-t border-gray-200"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.5 }}
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <Link to="/" className="flex justify-center">
            <img
              src="https://dreamservices.lon1.cdn.digitaloceanspaces.com/logo-dreamservices.svg"
              alt="Dream Services Logo"
              className="h-12"
            />
          </Link>
          <ul className="text-lg flex items-center justify-center flex-col gap-7 md:flex-row md:gap-12 transition-all duration-500 py-8">
            <li>
              <a
                href="https://www.club-dreamservices.com/content/2-mentions-legales"
                className="text-gray-800 hover:text-gray-900"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mentions Légales
              </a>
            </li>
            <li>
              <a
                href="https://www.club-dreamservices.com/content/3-conditions-utilisation"
                className="text-gray-800 hover:text-gray-900"
                target="_blank"
                rel="noopener noreferrer"
              >
                CGV
              </a>
            </li>
            <li>
              <a
                href="https://www.club-dreamservices.com/content/14-faq"
                className="text-gray-800 hover:text-gray-900"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQ
              </a>
            </li>
          </ul>

          <span className="text-xs text-gray-500 text-center block">
            ©<Link to="/"> Support Dreamservices 2024</Link> - Tous Droits
            Réservés.
          </span>
        </div>
      </div>
    </motion.footer>
  );
}

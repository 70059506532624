import Header from "../admin/Header.jsx";
import Footer from "../admin/Footer";
import SideBar from "../admin/SideBar";
import PropTypes from "prop-types";
import {isConnected, redirectTo} from "@/helpers/functions.js";

export default function AdminLayout({Component, props, ...rest}) {

        if (props.isProtected && !isConnected()) {
                redirectTo('/login');
        }


        return (
            <div className="grid grid-cols-2 gap-3">
                    <div>
                            <SideBar/>
                    </div>
                    <div>
                            <Header/>
                            <Component {...props} />
                            <Footer/>
                    </div>
            </div>
        );
}

AdminLayout.propTypes = {
        Component: PropTypes.elementType.isRequired,
        props: PropTypes.any,
        rest: PropTypes.object,
};
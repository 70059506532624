import {
    Button,
    Hr,
    Link,
    Text,
} from "@react-email/components";
import * as React from "react";
import EmailLayout from "../../layout/EmailLayout.jsx";
import PropTypes from "prop-types";


export default function WelcomeTicketPlatform({ user }) {
    const hr = {
        borderColor: "#e6ebf1",
        margin: "20px 0",
    };

    const paragraph = {
        color: "#525f7f",
        fontSize: "16px",
        lineHeight: "24px",
        textAlign: "left",
    };

    const anchor = {
        color: "#556cd6",
    };

    const button = {
        backgroundColor: "#656ee8",
        borderRadius: "5px",
        color: "#fff",
        fontSize: "16px",
        fontWeight: "bold",
        textDecoration: "none",
        textAlign: "center",
        display: "block",
        width: "100%",
        padding: "10px",
    };

    return (
        <EmailLayout>
            <Text style={paragraph}>
                Bienvenue sur notre plateforme de tickets client ! {user?.username || ''}
            </Text>
            <Text style={paragraph}>
                Nous sommes ravis de vous compter parmi nos utilisateurs. Grâce à notre plateforme, vous pouvez
                facilement suivre et gérer vos tickets de support client en toute simplicité.
            </Text>
            <Button style={button} href="https://support.club-dreamservices.com/login">
                Accéder à votre tableau de bord
            </Button>
            <Hr style={hr}/>
            <Text style={paragraph}>
                Si vous avez besoin d'assistance pour démarrer, nous vous recommandons de consulter notre{" "}
                <Link style={anchor} href="https://support.club-dreamservices.com/docs">
                    documentation d'utilisation
                </Link>.
            </Text>
            <Text style={paragraph}>
                Vous pouvez suivre le statut de vos tickets, mettre à jour les informations, et contacter notre support
                technique via le tableau de bord.
            </Text>
            <Text style={paragraph}>
                Pour garantir une meilleure utilisation de la plateforme, veuillez consulter notre{" "}
                <Link style={anchor} href="https://support.club-dreamservices.com/guide">
                    guide de démarrage rapide
                </Link>.
            </Text>
            <Text style={paragraph}>
                N'hésitez pas à nous contacter si vous avez des questions ou des préoccupations. Notre équipe de support
                est toujours disponible pour vous aider. Visitez notre{" "}
                <Link style={anchor} href="https://support.club-dreamservices.com">
                    centre de support
                </Link>.
            </Text>
            <Text style={paragraph}>— L'équipe de DreamServices</Text>
        </EmailLayout>
    );
}


WelcomeTicketPlatform.propTypes = {
    user: PropTypes.shape({
            username: PropTypes.string.isRequired,
    }).isRequired,
}
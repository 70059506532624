import { useEffect, useRef, useState } from "react";
import EditMessageModal from "./EditMessageModal";
import { motion } from "framer-motion";
import PropTypes from "prop-types";

export default function ManageModal({
  closeModal,
  style,
  selectedMessage,
  setMessage,
  setMessages,
  messages,
}) {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      !isEditModalOpen && // Ne pas fermer si l'EditMessageModal est ouverte
      modalRef.current &&
      !modalRef.current.contains(event.target)
    ) {
      closeModal();
    }
  };

  const handleKeyDown = (event) => {
    if (!isEditModalOpen && event.key === "Escape") {
      closeModal();
    }
  };

  useEffect(() => {
    // Attache les événements seulement si la ManageModal est active
    if (!isEditModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isEditModalOpen, closeModal]);

  return (
    <>
      <motion.div
        ref={modalRef}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.3, ease: "easeOut" }}
        className="absolute z-50 w-52 bg-gray-100 rounded-lg p-4 flex flex-col gap-2 shadow-lg"
        style={style}
      >
        <ul className="list-none flex flex-col gap-2 px-2">
          <li
            className="flex items-center text-gray-800 gap-2 transition-all duration-300 ease-out p-1.5 rounded-md cursor-pointer hover:bg-primary hover:text-white"
            onClick={() => setEditModalOpen(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              viewBox="0 0 24 24"
            >
              <path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" />
              <path d="m15 5 4 4" />
            </svg>
            <p className="font-semibold">Modifier</p>
          </li>
        </ul>
      </motion.div>
      <EditMessageModal
        isOpen={isEditModalOpen}
        closeModal={() => setEditModalOpen(false)}
        message={selectedMessage} // Message sélectionné à modifier
        messages={messages}
        setMessages={setMessages}
        setMessage={setMessage}
      />
    </>
  );
}

ManageModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  style: PropTypes.object,
  selectedMessage: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  setMessages: PropTypes.func.isRequired,
};

/* Bouton Supprimer

<div className="border-t-2 border-gray-300" />
      <ul className="list-none flex flex-col gap-2 px-2">
        <li className="flex items-center text-alert gap-2 transition-all duration-300 ease-out p-1.5 rounded-md cursor-pointer hover:bg-alertHover hover:text-white hover:transform hover:translate-x-0.5 hover:translate-y-[-0.25rem]">
          <svg
            className="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            viewBox="0 0 24 24"
          >
            <path d="M3 6h18" />
            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
            <line x1={10} x2={10} y1={11} y2={17} />
            <line x1={14} x2={14} y1={11} y2={17} />
          </svg>
          <p className="font-semibold">Supprimer</p>
        </li>
      </ul> */

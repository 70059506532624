import { useState, useEffect } from "react";
import {
  XMarkIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import axios from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { checkUser } from "@/helpers/functions.js";
import MessageModal from "@/components/MessageModal.jsx";

export default function Messages() {
  const [conv, setConv] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isUrlModalOpen, setIsUrlModalOpen] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [currentUser, setCurrentUser] = useState(null);

  const openModal = (conversation) => {
    setSelectedConversation(conversation);
    setIsModalOpen(true);
    setIsClosing(false);
  };

  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsModalOpen(false);
      setSelectedConversation(null);
    }, 300);
  };

  const handleSendMessage = () => {
    if ((message.trim() || file) && selectedConversation) {
      const newMessage = {
        sender: currentUser._id,
        //type: file ? "image" : "text",
        message: message.trim(),
        conversation: selectedConversation._id,
      };

      axios
          .post(`/messages/${selectedConversation._id}`, newMessage)
          .then((response) => {
            setMessages([...messages, response.data]);
          })
          .catch(() => {
            toast.error("Erreur lors de l'envoi du message.");
          });

      setMessage("");
      setFile(null);
    }
  };

  const openUrlModal = () => {
    setIsUrlModalOpen(true);
  };

  const closeUrlModal = () => {
    setIsUrlModalOpen(false);
    setImageUrl("");
  };

  const handleAddImageUrl = () => {
    if (imageUrl.trim() && selectedConversation) {
      const newMessage = {
        sender: "Moi",
        type: "image",
        content: imageUrl.trim(),
        time: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
      };

      setSelectedConversation((prev) => ({
        ...prev,
        messages: [...prev.messages, newMessage],
      }));

      closeUrlModal();
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const fetchConv = async () => {
    await axios
      .get("/conversations")
      .then((response) => {
        setConv(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchConv().then((r) => r);
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isModalOpen]);

  const fetchMessages = async (conversationId) => {
    await axios
      .get(`/messages/${conversationId}`)
      .then((response) => {
        setMessages(response.data);
      })
      .catch(() => {
        toast.error(
          "Erreur lors de la récupération des messages de la conversation sélectionnée."
        );
      });
  };

  useEffect(() => {
    if (selectedConversation?._id) {
      fetchMessages(selectedConversation._id).then((r) => r);
    }
  }, [selectedConversation]);

  useEffect(() => {
    setCurrentUser(checkUser);
  }, []);

  return (
    <>
      {/* Section des conversations */}
      <motion.section
        className="py-24 relative"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.5 }}
      >
        <div className="w-full max-w-7xl px-4 md:px-5 lg:px-6 mx-auto">
          <h2 className="font-bold text-4xl leading-10 text-black text-center">
            Mes Conversations
          </h2>
          <p className="mt-4 font-normal text-lg leading-8 text-gray-500 text-center">
            Retrouvez ici la liste de vos conversations au sujet de vos tickets.
          </p>
          <p className="mt-1 font-normal text-lg leading-8 text-gray-500 mb-11 text-center">
            Vous et les administrateurs peuvent engager une nouvelle conversation
            avec vos tickets.
          </p>
          <div className="main-box border border-gray-200 rounded-xl max-w-xl max-lg:mx-auto lg:max-w-full">
            {conv?.conversations?.length > 0 ? (
              conv?.conversations?.map((conv, index) => (
                <div
                  key={conv._id}
                  className="w-full max-w-7xl px-6 mx-auto cursor-pointer"
                  onClick={() => openModal(conv)}
                >
                  <div className="pt-11 px-2 pb-8 max-xl:max-w-2xl max-xl:mx-auto">
                    <div className="flex sm:items-center flex-col min-[400px]:flex-row justify-between gap-5 mb-4">
                      <div className="flex items-center gap-3">
                        <h6 className="font-semibold text-lg leading-8 text-primary">
                          {conv.ticket.ticketTitle}
                        </h6>
                      </div>
                      <p className="font-normal text-lg leading-8 text-gray-400">
                        {dayjs(conv.createdAt).format("DD/MM/YYYY")}
                      </p>
                    </div>
                    <p className="truncate text-gray-400 font-normal text-lg leading-8 md:max-w-[50ch] max-w-[20ch]">
                      {conv?.lastMessage?.message ||
                        "Aucun message reçu ou envoyé"}
                    </p>
                  </div>
                  {index !== conv?.conversations?.length - 1 && (
                    <div className="border-b border-gray-300 max-w-[90%] mx-auto"></div>
                  )}
                </div>
              ))
            ) : (
              <div className="flex flex-col items-center justify-center py-16 gap-6 w-full text-center">
                <ChatBubbleLeftRightIcon
                  className="w-24 h-24 md:w-48 md:h-48 text-primary"
                  style={{
                    fill: "rgb(233, 213, 255)",
                    stroke: "currentColor",
                    strokeWidth: 1.5,
                  }}
                />
                <h2 className="font-semibold text-2xl leading-8 text-black">
                  Aucun message
                </h2>
                <p className="font-normal text-lg leading-8 text-gray-500">
                  Vous n&apos;avez aucune conversation.
                </p>
              </div>
            )}
          </div>
        </div>
      </motion.section>

      <MessageModal
        closeModal={closeModal}
        isModalOpen={isModalOpen}
        isClosing={isClosing}
        selectedConversation={selectedConversation}
        messages={messages}
        message={message}
        setMessage={setMessage}
        setMessages={setMessages}
        handleSendMessage={handleSendMessage}
        handleFileChange={handleFileChange}
        currentUser={currentUser}
        openUrlModal={openUrlModal}
        file={file}
        setFile={setFile}
      />

      {isUrlModalOpen && (
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{ duration: 0.3 }}
          className="fixed inset-0 flex z-50 items-center justify-center bg-black bg-opacity-25"
        >
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full relative">
            {/* Header */}
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">
                Ajouter une URL d&apos;image
              </h2>
              <XMarkIcon
                className="w-6 h-6 cursor-pointer text-gray-500 hover:text-red-500"
                onClick={closeUrlModal}
              />
            </div>
            {/* Input Field */}
            <input
              type="text"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              placeholder="Entrez l'URL de l'image"
              className="w-full p-2 border rounded mb-4 focus:outline-none focus:ring-2 focus:ring-primary"
            />
            {/* Buttons */}
            <div className="flex justify-end space-x-2">
              <button
                onClick={closeUrlModal}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
              >
                Annuler
              </button>
              <button
                onClick={handleAddImageUrl}
                className="px-4 py-2 bg-primary text-white rounded hover:bg-opacity-90"
              >
                Ajouter
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
}

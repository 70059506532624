import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Header from "../app/Header.jsx";
import Footer from "../app/Footer";
import {isConnected, redirectTo} from "@/helpers/functions.js";

export default function ClientLayout({ Component, props, ...rest }) {
  const location = useLocation();

  // Vérifie si la page actuelle est "Login"
  const isLoginPage = location.pathname === "/login";

  // Vérifie si la page actuelle commence par "/ticket-detail"
  const isTicketDetailPage = location.pathname.startsWith("/ticket-detail");

  if (props.isProtected && !isConnected()) {
    redirectTo('/login');
  }

  return (
    <div>
      {!isLoginPage && <Header />}
      <main>
        <Component props={props} />
      </main>
      {!isLoginPage && !isTicketDetailPage && <Footer />}
    </div>
  );
}

ClientLayout.propTypes = {
  Component: PropTypes.elementType.isRequired,
  props: PropTypes.any,
    rest: PropTypes.object,
};

import axios from "axios";
import {toast} from "react-toastify";
import config from "@/config/index.js";

export function checkUser() {
        return localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user"))
            : undefined;
}

//TODO update the url and the return value
export function fetchUserData() {
        return checkUser()
            ? axios.get("/members/infos").then((r) => r.data.member)
            : checkUser();
}

export function isConnected() {
        return checkUser() && !localStorage.getItem('token') !== undefined;
}

export function logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        window.location.href = "/login";
}

export function protectedPage() {
        if (!isConnected() && window.location.pathname !== "/login") {
                window.location.href = "/login";
        }
}

export function isSuperAdmin() {
        return checkUser()?.role === "superadmin";
}

export function setUser(user) {
        localStorage.setItem("user", JSON.stringify(user));
}

export const redirectTo = (href) => {
        window.location.href = href; // Redirige l'utilisateur vers la page désirée
}

export const isImageUrl = (url) => {
        return url.match(/\.(jpeg|jpg|gif|png|svg|webp)$/) !== null;
}

export const getImageName = (url) => {
        return url.replace("https://dreamservices.lon1.digitaloceanspaces.com/", "");
}

export const parseStatus = (status) => {
        switch (status) {
                case "open":
                        return "Ouvert";
                case "closed":
                        return "Fermé";
                case "pending":
                        return "En attente";
                case "in-progress":
                        return "En cours";
                case "problem":
                        return "Problème";
                case "incident":
                        return "Incident";
                case "ticket":
                        return "Ticket";
                case "change":
                        return "Changement";
                case "release":
                        return "Version";
                case "service-request":
                        return "Demande de service";
                case "low":
                        return "Faible";
                case "medium":
                        return "Moyenne";
                case "high":
                        return "Élevée";
                case "urgent":
                        return "Urgente";

                default:
                        return status;
        }
};

export const handleUmamiEvent = (eventName, payload) => {
        if (window.umami) {
                window.umami.track({
                        website: config.unamiSiteKey,
                        url: window.location.href,
                        name: eventName,
                        data: {
                                ...payload,
                        }
                });
        }
}

export const handleIdentifyUmami = (user) => {
        if (window.umami) {
                window.umami.identify({
                        website: config.unamiSiteKey,
                        data: {
                                user: user
                        },
                        email: user?.email || "anonymous"
                });
        }
}
import { io } from 'socket.io-client';
import config from "./config";

// "undefined" means the URL will be computed from the `window.location` object
const URL = config.env === 'production' ? config.backendUrl_PROD : config.backendUrl_DEV;

export const socket = io(URL, {
    transports: ['websocket'],
    query: {
        token: localStorage.getItem("token"),
        user: localStorage.getItem("user")
    },
    auth: {
        token: localStorage.getItem("token"),
        user: localStorage.getItem("user"),
    },
    autoConnect: false,
});
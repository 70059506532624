import { Link, useLocation } from "react-router-dom";
import {
        TicketIcon,
        PencilSquareIcon,
        ChatBubbleLeftRightIcon, ArrowRightStartOnRectangleIcon,
} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";

export default function Header() {
  const location = useLocation();

  return (
    <>
      <motion.header
        className="bg-bgColor p-4 shadow-md sticky top-0 z-50"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ duration: 0.5 }}
      >
        <nav className="container mx-auto flex justify-between items-center px-4 md:px-12">
          <div className="text-body text-lg ">
            <Link to="/" id={"link-button"}
                  data-umami-event={"L'utilisateur se dirige vers l'accueil"}>
              <img
                src="https://dreamservices.lon1.cdn.digitaloceanspaces.com/logo-dreamservices.svg"
                alt="Dream Services Logo"
                className="sm:h-12 h-6"
              />
            </Link>
          </div>
          <ul className="flex items-center text-lg space-x-8">
            {/* Ticket Icon */}
            <li className="relative">
              <Link
                to="/"
                id={"link-button"}
                data-umami-event={"L'utilisateur se dirige vers l'accueil"}
                className={`transition duration-300 ${
                  location.pathname === "/" ? "text-primary" : "text-body"
                } hover:text-primary`}
              >
                <TicketIcon
                  className="h-7 w-7"
                  style={
                    location.pathname === "/"
                      ? {
                          fill: "rgb(233, 213, 255)", // Purple-200
                          stroke: "currentColor",
                          strokeWidth: 1.5,
                        }
                      : {
                          fill: "none",
                          stroke: "currentColor",
                          strokeWidth: 1.5,
                        }
                  }
                />
              </Link>
            </li>

            {/* Chat Icon */}
            <li className="relative">
              <Link
                to="/messages"
                id={"link-button"}
                data-umami-event={"L'utilisateur se dirige vers la messagerie"}
                className={`transition duration-300 ${
                  location.pathname === "/messages"
                    ? "text-primary"
                    : "text-body"
                } hover:text-primary`}
              >
                <ChatBubbleLeftRightIcon
                  className="h-7 w-7"
                  style={
                    location.pathname === "/messages"
                      ? {
                          fill: "rgb(233, 213, 255)", // Purple-200
                          stroke: "currentColor",
                          strokeWidth: 1.5,
                        }
                      : {
                          fill: "none",
                          stroke: "currentColor",
                          strokeWidth: 1.5,
                        }
                  }
                />
              </Link>
            </li>

            {/* Megaphone Icon */}
            <li className="relative">
              <Link
                to="/support"
                id={"link-button"}
                data-umami-event={"L'utilisateur se dirige vers la page de création de ticket"}
                className={`transition duration-300 ${
                  location.pathname === "/support"
                    ? "text-primary"
                    : "text-body"
                } hover:text-primary`}
              >
                <PencilSquareIcon
                  className="h-7 w-7"
                  style={
                    location.pathname === "/support"
                      ? {
                          fill: "rgb(233, 213, 255)",
                          stroke: "currentColor",
                          strokeWidth: 1.5,
                        }
                      : {
                          fill: "none",
                          stroke: "currentColor",
                          strokeWidth: 1.5,
                        }
                  }
                />
              </Link>
            </li>
                  {/* Megaphone Icon */}
                  <li className="relative md:pl-12">
                          <Link
                              id={"link-button"}
                              to="/logout"
                              className={`transition duration-300 ${
                                  location.pathname === "/logout"
                                      ? "text-alert"
                                      : "text-alert"
                              } hover:text-alertHover`}
                          >
                                  <ArrowRightStartOnRectangleIcon className="h-7 w-7"
                                                  style={
                                                          location.pathname === "/logout"
                                                              ? {
                                                                      fill: "rgb(233, 213, 255)",
                                                                      stroke: "currentColor",
                                                                      strokeWidth: 1.5,
                                                              }
                                                              : {
                                                                      fill: "none",
                                                                      stroke: "currentColor",
                                                                      strokeWidth: 1.5,
                                                              }
                                                  } />
                          </Link>
                  </li>
          </ul>
        </nav>
      </motion.header>
    </>
  );
}

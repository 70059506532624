import { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import PropTypes from "prop-types";
import axios from "axios";
import {toast} from "react-toastify";

export default function EditTicketModal({ isOpen, onClose, ticket }) {
  const modalRef = useRef(null); // Référence pour détecter les clics extérieurs
  const [formData, setFormData] = useState({
    ticketTitle: ticket?.ticketTitle || "",
    ticketDescription: ticket?.ticketDescription || "",
    type: ticket?.type || "",
  });

  useEffect(() => {
    if (ticket) {
      setFormData({
        ticketTitle: ticket.ticketTitle,
        ticketDescription: ticket.ticketDescription,
        type: ticket.type,
      });
    }
  }, [ticket]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    const newTicket = { ...ticket, ...formData}
    const response = await axios.put('/tickets/' + ticket._id, newTicket)

    if(response.status === 200) {
      toast.success("Ticket modifié avec succès !", {
        autoClose: 1250,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      onClose();
    } else {
        toast.error("Erreur lors de la modification du ticket");
    }
  };

  // Gestion des clics en dehors de la modale
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  // Gestion de la touche "Escape"
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape" && isOpen) {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 flex items-center justify-center z-50">
      <motion.div
        ref={modalRef} // Référence pour la modale
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        className="bg-white rounded-lg p-6 w-full max-w-md"
      >
        <h2 className="text-2xl font-bold mb-4 text-center mb-12">
          Modifier le ticket
        </h2>
        <div className="space-y-8 mb-12">
          {/* Titre */}
          <div>
            <label className="block text-sm mb-2 font-medium text-gray-700">
              Titre
            </label>
            <input
              type="text"
              name="ticketTitle"
              placeholder="Titre du ticket"
              value={formData.ticketTitle}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md p-2"
            />
          </div>

          {/* Description */}
          <div>
            <label className="block text-sm sm mb-2 font-medium text-gray-700">
              Description
            </label>
            <textarea
              name="ticketDescription"
              value={formData.ticketDescription}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md p-2"
            ></textarea>
          </div>

          <div>
            <label className="block text-sm sm mb-2 font-medium text-gray-700">
              Type de problème
            </label>
            <div className="relative w-full">
              <select
                name="type"
                value={formData.type}
                onChange={handleChange}
                required
                className={`w-full h-14 shadow-sm ${
                  formData.type ? "text-body" : "text-gray-400"
                } text-lg font-normal leading-7 rounded-xl border border-gray-300 focus:outline-none py-2 px-4 appearance-none`}
                style={{
                  WebkitAppearance: "none",
                  MozAppearance: "none",
                }}
              >
                <option value="" disabled hidden>
                  Choisir une catégorie
                </option>
                <option value="ticket">Ticket</option>
                <option value="incident">Accident</option>
                <option value="problem">Problème</option>
                <option value="change">Changement</option>
                <option value="release">Mise à jour</option>
                <option value="service-request">Demande spécifique</option>
              </select>

              <div
                className={`absolute right-6 transform -translate-y-1/2 -mr-2 pointer-events-none text-gray-400 top-1/2`}
              >
                <ChevronDownIcon className="h-5 w-5" />
              </div>
            </div>
          </div>
        </div>

        {ticket.ticketStatus !== "closed" ? (
            <div className="flex justify-end space-x-2 mt-4">
              <button
                  onClick={onClose}
                  data-umami-event="client-edit-ticket-modal-cancel"
                  data-umami-event-ticket={ticket._id}
                  data-umami-event-ticketTitle={ticket.ticketTitle}
                  className="px-4 py-2 bg-gray-200 rounded-md text-gray-700"
              >
                Annuler
              </button>
              <button
                  onClick={handleSubmit}
                  data-umami-event="client-edit-ticket-modal-save"
                  data-umami-event-ticket={ticket._id}
                  data-umami-event-ticketTitle={ticket.ticketTitle}
                  className="px-4 py-2 bg-primary text-white rounded-md"
              >
                Enregistrer
              </button>
            </div>
        ) : (<p className={"text-red-500"}>Modification impossible, le ticket à été fermé</p>)}
      </motion.div>
    </div>
  );
}

EditTicketModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  ticket: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string,
    ticketStatus: PropTypes.string,
    ticketTitle: PropTypes.string,
    ticketDescription: PropTypes.string,
    ticketType: PropTypes.string,
  }),
};

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TicketIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import axios from "axios";
import { toast } from "react-toastify";
import { createId } from "@paralleldrive/cuid2";
import {checkUser, handleUmamiEvent} from "@/helpers/functions.js";

export default function Contact() {
  const [selectedSubject, setSelectedSubject] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const generateTicketNumber = () => {
    return createId();
  };

  const [ticket, setTicket] = useState({
    ticketTitle: "",
    ticketDescription: "",
    ticketStatus: "open",
    ticketPriority: "low",
    ticketNumber: "",
    ticketPassword: "",
    clientPhone: "",
    type: "",
  });

  useEffect(() => {
    if (selectedSubject) {
      setTicket({
        ...ticket,
        ticketNumber: generateTicketNumber(),
        ticketPassword: generateTicketNumber(),
        type: selectedSubject,
      });
    }
  }, [selectedSubject]);

  const validateTicket = () => {
    if (!ticket.ticketTitle) {
      toast.error("Le titre du ticket est requis");
      return false;
    }
    if (!ticket.ticketDescription) {
      toast.error("La description du ticket est requise");
      return false;
    }
    if (!ticket.clientPhone) {
      toast.error("Le numéro de téléphone est requis");
      return false;
    }
    if (!ticket.type) {
      toast.error("Le type de ticket est requis");
      return false;
    }
    return true;
  };

  const createTicket = async (e) => {
    e.preventDefault();

    if (!validateTicket()) return;

    try {
      setSubmitted(true);
      const response = await axios.post("/tickets", ticket);
      if (response.status === 201) {
        setTicket({
          ticketTitle: "",
          ticketDescription: "",
          ticketStatus: "open",
          ticketPriority: "low",
          ticketNumber: "",
          clientPhone: "",
          type: "",
        });

        const user = checkUser();
        const notificationResponse = await axios.post("/notification", {
            userId: user._id,
            title: "Nouveau ticket créé",
            message: "Votre ticket a été créé avec succès",
            payload: {
                ticketId: response.data._id,
            },
        });

        if (notificationResponse.status === 201) {
          handleUmamiEvent("nouveau-ticket", {
            ticketId: response.data._id,
            ticketTitle: response.data.ticketTitle,
          })
           toast.success("Ticket créé avec succès");
        }

        setTimeout(() => {
          window.location.href = "/ticket-detail/" + response.data._id;
        }, 2000);
      }
    } catch (error) {
      console.error(error);
    }
    setSubmitted(false);
  };

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setSelectedSubject(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTicket({
      ...ticket,
      [name]: value,
    });
  };

  return (
    <>
      <motion.section
        className="w-full xl:py-24 lg:py-20 py-12"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.5 }}
      >
        <div className="w-full max-w-7xl px-6 lg:px-8 mx-auto">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-16 xl:gap-x-24 gap-y-14 max-w-lg md:max-w-3xl lg:max-w-full mx-auto">
            <div>
              <h1 className="text-gray-900 md:text-5xl text-4xl font-bold leading-tight mb-8 lg:text-left text-center">
                Créer un ticket
              </h1>
              <p className="text-gray-900 text-lg font-normal leading-7 lg:text-left text-center">
                Contactez notre support client pour toute question
              </p>
              <div className="my-12 grid grid-cols-1 md:gap-x-8 gap-y-8">
                <Link
                  to="/"
                  className="rounded-2xl border border-gray-300 bg-white p-7 group transition-all duration-500 hover:bg-primary"
                >
                  <div className="w-14 h-14 bg-primary rounded-xl flex items-center justify-center mb-5 transition-all duration-500 group-hover:bg-white cursor-pointer">
                    <TicketIcon className="h-9 w-9 text-white transition-all duration-500 group-hover:text-primary" />
                  </div>
                  <h5 className="text-gray-900 text-xl font-semibold leading-8 mb-3 transition-all duration-500 group-hover:text-white">
                    Mes Tickets
                  </h5>
                  <p className="text-gray-500 text-sm font-normal leading-5 transition-all duration-500 group-hover:text-white">
                    Consultez et gérez vos tickets en quelques clics. Accédez
                    facilement à toutes vos demandes.
                  </p>
                </Link>
              </div>
            </div>
            <form
              action="#"
              className="h-fit bg-white border border-slate-200 rounded-2xl lg:p-12 p-8 w-full max-w-lg md:max-w-3xl lg:max-w-full mx-auto"
            >
              <div className="relative text-gray-500 focus-within:text-gray-900">
                <input
                  type="text"
                  id="ticketTitle"
                  name={"ticketTitle"}
                  value={ticket.ticketTitle}
                  onChange={handleChange}
                  className="w-full block h-12 pr-5 pl-4 py-2.5 text-lg leading-7 font-normal shadow-xs text-gray-900 bg-transparent border border-gray-300 rounded-xl placeholder-gray-400 focus:outline-none"
                  placeholder="Titre du ticket"
                  required
                />
              </div>
              <p className="text-gray-400 mt-2 mb-6 px-2 text-xs text-left">
                <span className="text-alert">*</span> Requis
              </p>

              <div className="relative w-full">
                <select
                  name="subject"
                  value={selectedSubject}
                  onChange={handleSelectChange}
                  required
                  className={`w-full h-14 shadow-sm ${
                    selectedSubject ? "text-body" : "text-gray-400"
                  } text-lg font-normal leading-7 rounded-xl border border-gray-300 focus:outline-none py-2 px-4 appearance-none`}
                  style={{
                    WebkitAppearance: "none",
                    MozAppearance: "none",
                  }}
                >
                  <option value="" disabled hidden>
                    Choisir une Catégorie
                  </option>
                  <option value="ticket">Ticket</option>
                  <option value="incident">Accident</option>
                  <option value="problem">Problème</option>
                  <option value="change">Changement</option>
                  <option value="release">Mise à jour</option>
                  <option value="service-request">Demande spécifique</option>
                </select>

                <div
                  className={`absolute right-6 transform -translate-y-1/2 -mr-2 pointer-events-none text-gray-400 ${
                    selectedSubject === "autre" ? "top-1/4" : "top-1/2"
                  }`}
                >
                  <ChevronDownIcon className="h-5 w-5" />
                </div>
              </div>
              <p className="text-gray-400 mt-2 mb-6 px-2 text-xs text-left">
                <span className="text-alert">*</span> Requis
              </p>

              <div className="relative">
                <textarea
                  id="ticketDescription"
                  className="block w-full h-40 px-4 py-2.5 text-lg leading-7 font-normal shadow-xs text-gray-900 bg-transparent border border-gray-300 rounded-2xl placeholder-gray-400 focus:outline-none resize-none"
                  placeholder="Décrivez le problème ou la demande..."
                  name={"ticketDescription"}
                  value={ticket.ticketDescription}
                  onChange={handleChange}
                  required
                />
              </div>
              <p className="text-gray-400 mt-2 mb-6 px-2 text-xs text-left">
                <span className="text-alert">*</span> Requis
              </p>

              <div className="relative text-gray-500 focus-within:text-gray-900 ">
                <input
                  type="tel"
                  id="clientPhone"
                  className="w-full block h-12 pr-5 pl-4 py-2.5 text-lg leading-7 font-normal shadow-xs text-gray-900 bg-transparent border border-gray-300 rounded-xl placeholder-gray-400 focus:outline-none"
                  placeholder="Numéro de téléphone"
                  name={"clientPhone"}
                  value={ticket.clientPhone}
                  onChange={handleChange}
                  required
                />
              </div>
              <p className="text-gray-400 mt-2 mb-6 px-2 text-xs text-left">
                <span className="text-alert">*</span> Requis
              </p>

              <button
                disabled={submitted}
                onClick={createTicket}
                className="w-full h-12 rounded-xl bg-primary hover:bg-primaryHover disabled:bg-gray-500 transition-all duration-300 shadow-sm text-white font-semibold leading-6 flex items-center justify-center"
              >
                Créer le ticket
              </button>
            </form>
          </div>
        </div>
      </motion.section>
    </>
  );
}

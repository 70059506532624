import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.jsx";
import "./assets/output.css";
import axios from "axios";
import config from "@/config";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import relativeTime from "dayjs/plugin/relativeTime";
import {redirectTo} from "@/helpers/functions.js";

dayjs.locale("fr");
dayjs.extend(relativeTime);

// Configuration d'axios avec un interceptor pour gérer le token
axios.interceptors.request.use((c) => {
  const token = localStorage.getItem("token");
  if (token) {
    const decodedToken = jwtDecode(token); // Décodage du token
    if (decodedToken.exp * 1000 < Date.now()) {
      // Vérifie si le token a expiré
      localStorage.removeItem("token"); // Supprime le token expiré
      localStorage.removeItem("user"); // Supprime les données de l'utilisateur
      toast.warning(
        "Votre session a expiré. Certaines fonctionnalités nécessitant une connexion pourraient ne pas être disponibles."
      );
      redirectTo('/login');
    } else {
      c.headers["Authorization"] = `Bearer ${token}`; // Ajoute le token aux headers
    }
  }

  c.baseURL = config.env === "development" ? config.backendUrl_DEV : config.backendUrl_PROD; // Définit l'URL de base à partir de la config
  return c;
});

createRoot(document.getElementById("root")).render(
  <StrictMode>
    <App />
  </StrictMode>
);

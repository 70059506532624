import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import dayjs from "dayjs";

const truncateDescription = (text, charLimit) => {
  if (text.length > charLimit) {
    return text.slice(0, charLimit) + "...";
  }
  return text;
};

const ItemTicket = ({
  title,
  ticketDescription: description,
  _id,
  ticketStatus: status,
  createdAt,
  className,
}) => {
  const statusStyle =
    status === "closed"
      ? "font-medium text-sm leading-6 whitespace-nowrap py-0.5 px-3 rounded-full lg:mt-3 bg-green-200 text-validation"
      : "font-medium text-sm leading-6 py-0.5 px-3 whitespace-nowrap rounded-full lg:mt-3 bg-purple-200 text-primary";

  return (
    <div
      className={`flex flex-col lg:flex-row items-center py-6 gap-6 w-full ${className}`}
    >
      <div className="flex flex-row items-center w-full">
        <div className="grid grid-cols-1 lg:grid-cols-2 w-full">
          <div className="flex items-center">
            <div>
              <h2 className="font-semibold text-xl leading-8 text-black mb-3 w-full">
                {title}
              </h2>
              <p className="font-normal text-lg leading-8 text-gray-500 mb-3">
                {truncateDescription(description, 50)}
              </p>

              <Link
                to={`/ticket-detail/${_id}`}
                id={"link-button"}
                data-umami-event={"L'utilisateur se dirige vers les détails du ticket: " + title + " - " + _id}
                className="text-primary font-medium mt-8 block transition duration-300 hover:underline"
              >
                Détails
              </Link>
            </div>
          </div>
          <div className="grid grid-cols-5">
            <div className="col-span-5 lg:col-span-1 flex items-center max-lg:mt-3">
              <div className="flex gap-3 lg:block"></div>
            </div>
            <div className="col-span-5 lg:col-span-2 flex items-center max-lg:mt-3">
              <div className="flex gap-3 lg:block">
                <p className="font-medium text-sm leading-7 text-black">
                  Statut
                </p>
                <p className={statusStyle}>{status}</p>
              </div>
            </div>
            <div className="col-span-5 lg:col-span-2 flex items-center max-lg:mt-3">
              <div className="flex gap-3 lg:block">
                <p className="font-medium text-sm whitespace-nowrap leading-6 text-black">
                  Généré le :
                </p>
                <p className="font-medium whitespace-nowrap leading-7 lg:mt-3 text-primary">
                  {dayjs(createdAt).format("DD/MM/YYYY")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ItemTicket.propTypes = {
  title: PropTypes.string.isRequired,
  ticketDescription: PropTypes.string.isRequired,
  _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  ticketStatus: PropTypes.string.isRequired,
  ticketNumber: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ItemTicket;

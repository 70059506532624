import {useEffect, useState} from "react";
import TicketsContainer from "../../components/TicketContainer";
import { motion } from "framer-motion";
import axios from "axios";
import {useNavigate} from "react-router-dom";

export default function Home() {
  const [tickets] = useState([]);
    const [mdbTickets, setMdbTickets] = useState([]);
    const navigate = useNavigate();

    const fetchTickets = async () => {
    try {
        const response = await axios.get("/tickets/user");
        setMdbTickets(response.data);
    } catch (error) {
        console.error(error);
    }
  }

  useEffect(() => {
    if(localStorage.getItem("token")) {
        fetchTickets().then(r => r);
    }
  }, [])

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.5 }}
    >
      <section className="py-24 relative">
        <div className="w-full max-w-7xl px-4 md:px-5 lg-6 mx-auto">
          <h2 className="font-bold text-4xl leading-10 text-black text-center">
            Mes Tickets
          </h2>
          <p className="mt-4 font-normal text-lg leading-8 text-gray-500 mb-11 text-center">
            Retrouvez ici la liste de vos tickets.
          </p>
          <div className="main-box border border-gray-200 rounded-xl pt-6 max-w-xl max-lg:mx-auto lg:max-w-full">
            <div className="flex flex-row lg:flex-row items-center justify-between px-6 pb-6 border-b border-gray-200">
              <div className="data">
                <p className="font-semibold text-base leading-7 text-black">
                  Mes tickets{" "}
                  <span className="text-xs align-text-top">
                    ({mdbTickets.length})
                  </span>
                </p>
              </div>
              <button
                onClick={() => {
                  navigate("/support");
                }}
                className="rounded-full py-2 md:py-3 px-7 font-semibold text-sm leading-7 text-white bg-primary shadow-sm shadow-transparent transition-all duration-300 hover:bg-primaryHover"
                id={"button"}
                data-umami-event={"L'utilisateur se dirige vers la page de création de ticket"}
              >
                Générer un ticket
              </button>
            </div>

            <TicketsContainer tickets={tickets} mdbTickets={mdbTickets} />
          </div>
        </div>
      </section>
    </motion.div>
  );
}

import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  EyeIcon,
  EyeSlashIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import ReactDOMServer from "react-dom/server";
import WelcomeTicketPlatform from "@/emails/welcome/user-registered";
import {handleIdentifyUmami, handleUmamiEvent} from "@/helpers/functions.js";

export default function Login() {
  const [isSignUp, setIsSignUp] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };
  const [isLoading, setIsLoading] = useState(false);

  const toggleForm = () => {
    setIsSignUp((prev) => !prev);
    setFormData({
      email: "",
      password: "",
      enterprise: "",
      type: "cse",
      confirmPassword: "",
      username: "",
    });
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email.trim()) newErrors.email = "Adresse e-mail requise.";
    if (isSignUp && !formData.username.trim())
      newErrors.username = "Nom d'utilisateur requis.";
    if (!formData.password) newErrors.password = "Mot de passe requis.";
    if (isSignUp && formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Les mots de passe ne correspondent pas.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      if (isSignUp) {
        console.log("Sign up data:", formData);
      } else {
        console.log("Login data:", formData);
      }
    }, 2000); // Simule une requête réseau
  };

  const animationVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const loginUser = async () => {
    if (!formData.email || !formData.password) {
      toast.error("Tous les champs sont requis");
      setErrors({ email: "Adresse e-mail requise", password: "Mot de passe requis" });
    } else {
      try {
        await axios
          .post("/login", formData)
          .then((res) => {
            toast.success("Connecté avec succès");
            if (res.data.token) {
              const user = jwtDecode(res.data.token);
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("user", JSON.stringify(user));

              handleIdentifyUmami(user); // track session with umami

              setTimeout(() => {
                window.location.href = "/"; // redirect user to home after login
              }, 500)
            }
          })
          .catch(() => {
            toast.error("Identifiants invalides");
          })
          .finally(() => {
            setIsLoading(false);
            setFormData({ email: "", password: "" });
          });
      } catch (e) {
        console.error(e);
      }
    }
  };

  const registerUser = async () => {
    if (!formData.email || !formData.password || !formData.username || !formData.enterprise || !formData.type) {
      toast.error("Tous les champs sont requis");
      setErrors({ email: "L'email est requis" });
    } else {
      if(formData.password !== formData.confirmPassword) {
        toast.error("Les mots de passe ne correspondent pas");
        setErrors({ confirmPassword: "Les mots de passe ne correspondent pas" });
        return;
      }

      if(formData.password.length < 6) {
        toast.error("Le mots de passe doit contenir 6 caractères minimum");
        setErrors({ password: "Le mots de passe doit contenir 6 caractères minimum" });
        return;
      }

      formData.userName = formData.username;
      formData.role = "user";
      await axios
        .post("/register", formData)
        .then(async (res) => {
          if(res.status === 201) {
            toast.success("Compte créé avec succès, vous pouvez maintenant vous connecter");

            const response = await axios.post("/send-mail", {
              email: formData.email,
              subject: "Bienvenue sur DreamServices Support",
              message: ReactDOMServer.renderToStaticMarkup(<WelcomeTicketPlatform user={formData}/>)
            })

            if(response.status === 200) {
              toast.info("Email de bienvenue envoyé avec succès");
            } else {
              toast.error("Une erreur s'est produite lors de l'envoi de l'email de bienvenue");
            }

            setIsSignUp(false);
          } else {
            toast.error("Une erreur s'est produite lors de la création de l'utilisateur");
          }
        })
        .catch(() => {
          toast.error("Une erreur s'est produite lors de la création de l'utilisateur");
        })
        .finally(() => {
          setIsLoading(false);
          setFormData({ email: "", password: "" });
        });
    }
  };

  const handleSendEvent = () => {
    const payload = isSignUp ? {
        username: formData.username,
        email: formData.email,
        enterprise: formData.enterprise,
        type: formData.type,
    } : {
        email: formData.email || 'no email',
        username: formData.username || 'no username',
    }

    handleUmamiEvent(isSignUp ? "L'utilisateur s'est enregistré" : "L'utilisateur s'est connecté", payload);
  }

  return (
    <>
      <section className="flex justify-center relative">
        <img
          src="https://dreamservices.lon1.cdn.digitaloceanspaces.com/linear-background.svg"
          alt="gradient background"
          className="w-full h-full object-cover fixed"
        />
        <div className="mx-auto max-w-xl px-6 lg:px-8 absolute py-20">
          <img
            src="https://dreamservices.lon1.cdn.digitaloceanspaces.com/logo-dreamservices-white.svg"
            className="w-48 mx-auto lg:mb-11 mb-8 object-cover"
           alt={"Dreamservices Logo"}/>
          <div className="rounded-2xl bg-white shadow-xl">
            <form className="lg:p-11 p-7 mx-auto" onSubmit={handleSubmit}>
              <div className="mb-11">
                <AnimatePresence mode="wait">
                  <motion.h1
                    key={isSignUp ? "signup-title" : "login-title"}
                    variants={animationVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 0.3 }}
                    className="text-gray-900 text-center text-3xl font-bold leading-10 mb-2"
                  >
                    {isSignUp ? "Créer un compte" : "Connexion à votre espace"}
                  </motion.h1>
                  <motion.p
                    key={isSignUp ? "signup-subtitle" : "login-subtitle"}
                    variants={animationVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 0.3 }}
                    className="text-gray-500 text-center text-base font-medium leading-6"
                  >
                    {isSignUp
                      ? "Créez un compte pour gérer vos tickets simplement."
                      : "Générez et suivez vos tickets facilement."}
                  </motion.p>
                </AnimatePresence>
              </div>
              <AnimatePresence mode="wait">
                {isSignUp && (
                  <motion.div
                    key="username-container"
                    className="mb-6"
                    variants={animationVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 0.3 }}
                  >
                    <input
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                      type="text"
                      min={3}
                      max={20}
                      className={`w-full h-12 text-gray-900 placeholder:text-gray-400 text-lg font-normal leading-7 rounded-full border shadow-sm px-4 ${
                        errors.username ? "border-red-500" : "border-gray-300"
                      }`}
                      placeholder="Johndoe"
                    />
                    {errors.username && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.username}
                      </p>
                    )}
                  </motion.div>
                )}
                {/* Input pour l'email */}
                <motion.div
                  key="email-container"
                  variants={animationVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.3 }}
                  className="mb-6"
                >
                  <input
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    type="email"
                    className={`w-full h-12 text-gray-900 placeholder:text-gray-400 text-lg font-normal leading-7 rounded-full border shadow-sm px-4 ${
                      errors.email ? "border-red-500" : "border-gray-300"
                    }`}
                    placeholder="Adresse e-mail"
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                  )}
                </motion.div>

                {isSignUp && (
                  <>
                    {/* Champ pour le nom de l'entreprise */}
                    <motion.div
                      key="company-name-container"
                      className="mb-6"
                      variants={animationVariants}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      transition={{ duration: 0.3 }}
                    >
                      <input
                        name="enterprise"
                        value={formData.enterprise || ""}
                        onChange={handleChange}
                        type="text"
                        className={`w-full h-12 text-gray-900 placeholder:text-gray-400 text-lg font-normal leading-7 rounded-full border shadow-sm px-4 ${
                          errors.enterprise
                            ? "border-red-500"
                            : "border-gray-300"
                        }`}
                        placeholder="Nom de l'entreprise"
                      />
                      {errors.enterprise && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.enterprise}
                        </p>
                      )}
                    </motion.div>

                    {/* Champ pour sélectionner une option */}
                    <motion.div
                      key="select-option-container"
                      className="mb-6 relative"
                      variants={animationVariants}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      transition={{ duration: 0.3 }}
                    >
                      <select
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                        required
                        className={`w-full h-14 shadow-sm ${
                          formData.type ? "text-body" : "text-gray-400"
                        } text-lg font-normal leading-7 rounded-full border border-gray-300 focus:outline-none py-2 px-4 appearance-none pr-10`}
                        style={{
                          WebkitAppearance: "none",
                          MozAppearance: "none",
                        }}
                      >
                        <option value="">Sélectionnez votre rôle</option>
                        <option value="client">Client</option>
                        <option value="cse">CSE</option>
                      </select>
                      <ChevronDownIcon className="w-6 h-6 absolute right-4 top-1/2 transform -translate-y-1/2 pointer-events-none text-gray-500" />
                      {errors.type && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.type}
                        </p>
                      )}
                    </motion.div>
                  </>
                )}

                {/* Input pour le mot de passe */}
                <motion.div
                  key="password-container"
                  className="relative mb-6"
                  variants={animationVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.3 }}
                >
                  <input
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    type={showPassword ? "text" : "password"}
                    className={`w-full h-12 text-gray-900 placeholder:text-gray-400 text-lg font-normal leading-7 rounded-full border shadow-sm px-4 ${
                      errors.password ? "border-red-500" : "border-gray-300"
                    }`}
                    placeholder="Mot de passe"
                  />
                  <button
                    type="button"
                    id={"password-visibility-button"}
                    onClick={togglePasswordVisibility}
                    className="absolute right-3 top-3 text-gray-500"
                  >
                    {showPassword ? (
                      <EyeIcon className="h-6 w-6" />
                    ) : (
                      <EyeSlashIcon className="h-6 w-6" />
                    )}
                  </button>
                  {errors.password && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.password}
                    </p>
                  )}
                </motion.div>

                {/* Input pour confirmer le mot de passe (inscription uniquement) */}
                {isSignUp && (
                  <motion.div
                    key="confirm-password-container"
                    className="relative mb-6"
                    variants={animationVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 0.3 }}
                  >
                    <input
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      type={showConfirmPassword ? "text" : "password"}
                      className={`w-full h-12 text-gray-900 placeholder:text-gray-400 text-lg font-normal leading-7 rounded-full border shadow-sm px-4 ${
                        errors.confirmPassword
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                      placeholder="Confirmer le mot de passe"
                    />
                    <button
                      type="button"
                        id={"confirm-password-visibility-button"}
                      onClick={toggleConfirmPasswordVisibility}
                      className="absolute right-3 top-3 text-gray-500"
                    >
                      {showConfirmPassword ? (
                        <EyeIcon className="h-6 w-6" />
                      ) : (
                        <EyeSlashIcon className="h-6 w-6" />
                      )}
                    </button>
                    {errors.confirmPassword && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.confirmPassword}
                      </p>
                    )}
                  </motion.div>
                )}
              </AnimatePresence>

              {/* Bouton de soumission */}
              <motion.button
                key="submit-button"
                type="submit"
                variants={animationVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.3 }}
                disabled={isLoading}
                onClick={async (e) => {
                  e.preventDefault();
                  if (isSignUp) {
                    await registerUser();
                  } else {
                    await loginUser();
                  }
                  handleSendEvent();
                }}
                className="w-full h-12 text-white text-center font-semibold rounded-full hover:bg-primaryHover transition-all duration-300 bg-primary shadow-sm mb-11"
              >
                {isLoading
                  ? "Chargement..."
                  : isSignUp
                  ? "S'inscrire"
                  : "Se connecter"}
              </motion.button>

              {/* Bouton pour basculer entre connexion et inscription */}
              <motion.div
                key="toggle-container"
                className="flex flex-col items-center text-center md:flex-row md:justify-center md:items-center md:space-x-3"
                variants={animationVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.3 }}
              >
                <p className="text-gray-900 font-medium leading-6">
                  {isSignUp
                    ? "Vous avez déjà un compte ?"
                    : "Vous n'avez pas de compte ?"}
                </p>
                <button
                  type="button"
                  onClick={toggleForm}
                  id={isSignUp ? "login-form-button": "signup-form-button" }
                  data-umami-event={isSignUp ? "L'utilisateurs veut s'enregistré" : "L'utilisateur veut se connecté"}
                  data-umami-event-username={formData.userName}
                  data-umami-event-email={formData.email}
                  className="text-primary hover:text-primaryHover transition duration-300 font-semibold mt-2 md:mt-0"
                >
                  {isSignUp ? "Se connecter" : "S'inscrire"}
                </button>
              </motion.div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { useEffect } from "react";
import {toast} from "react-toastify";
import axios from "axios";

export default function ConfirmDeleteModal({ isOpen, onClose, ticket }) {
  // Gestion de la fermeture en cliquant sur l'extérieur
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  // Gestion de la fermeture avec la touche "Escape"
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape" && isOpen) {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose]);

  const handleCloseTicket = async () => {
    const response = await axios.patch('/tickets/' + ticket._id, { ticketStatus: "closed" })

    if(response.status === 200) {
        toast.success("Ticket fermé avec succès !");
    } else {
        toast.error("Erreur lors de la fermeture du ticket");
    }

    onClose(); // Remplacez cette logique par une action réelle si nécessaire
  }

  if (!isOpen || !ticket) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-25 flex items-center justify-center z-50"
      onClick={handleBackdropClick}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        className="bg-white rounded-lg p-6 w-full max-w-lg"
        onClick={(e) => e.stopPropagation()} // Empêche la propagation du clic vers le backdrop
      >
        <h2 className="text-2xl font-bold text-left mb-4">
          Confirmer la fermeture
        </h2>
        <p className="text-gray-700 text-left mb-6">
          Êtes-vous sûr de vouloir fermer ce ticket ? Cette action est
          irréversible.
        </p>
        <div className="flex justify-end space-x-4">
          <button
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md"
            onClick={onClose}
            data-umami-event="client-close-ticket-modal-cancel"
            data-umami-event-ticket={ticket._id}
            data-umami-event-ticketTitle={ticket.ticketTitle}
          >
            Annuler
          </button>
          <button
            className="px-4 py-2 bg-alert text-white rounded-md"
            onClick={handleCloseTicket}
            data-umami-event="client-close-ticket-modal-confirm"
            data-umami-event-ticket={ticket._id}
            data-umami-event-ticketTitle={ticket.ticketTitle}
          >
            Confirmer
          </button>
        </div>
      </motion.div>
    </div>
  );
}

ConfirmDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
    ticket: PropTypes.object.isRequired,
};

import {Body, Container, Head, Hr, Html, Img, Section, Text, Tailwind} from "@react-email/components";
import PropTypes from "prop-types";

const main = {
        backgroundColor: "#f6f9fc",
        fontFamily:
            '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif',
};

const container = {
        backgroundColor: "#ffffff",
        margin: "0 auto",
        padding: "20px 0 48px",
        marginBottom: "64px",
};

const box = {
        padding: "0 48px",
};

const hr = {
        borderColor: "#e6ebf1",
        margin: "20px 0",
};

const footer = {
        color: "#8898aa",
        fontSize: "12px",
        lineHeight: "16px",
};


export default function EmailLayout({ children }) {
        return (
            <Html>
                    <Head />
                    <Body style={main}>
                            <Container style={container}>
                                    <Section style={box}>
                                            <Img
                                                src={`https://dreamservices.lon1.cdn.digitaloceanspaces.com/logo-dreamservices.svg`}
                                                width="100"
                                                height="100"
                                                alt="DreamServices Logo"
                                            />
                                            <Hr style={hr} />
                                            <Tailwind config={{
                                                    theme: {
                                                            extend: {
                                                                    colors: {
                                                                            brand: "#007291",
                                                                    },
                                                            },
                                                    },
                                            }}>
                                                    {children}
                                            </Tailwind>
                                            <Hr style={hr} />
                                            <Text style={footer}>
                                                    DreamServices, 64 av Alfred Lefrançois, 59120 Tourcoing, France
                                            </Text>
                                    </Section>
                            </Container>
                    </Body>
            </Html>
        )
}

EmailLayout.propTypes = {
        children: PropTypes.node.isRequired,
}
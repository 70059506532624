import {TicketIcon} from "@heroicons/react/24/outline";
import ItemTicket from "./ItemTicket";
import PropTypes from "prop-types";

const TicketsContainer = ({mdbTickets}) => {
        return (
            <div className="w-full px-3 min-[400px]:px-6">
                    {mdbTickets && mdbTickets.length > 0 ? (
                        mdbTickets?.map((ticket, index) => (
                            <ItemTicket
                                key={ticket._id}
                                title={ticket.ticketTitle}
                                ticketDescription={ticket.ticketDescription}
                                _id={ticket._id}
                                ticketStatus={ticket.ticketStatus}
                                createdAt={ticket.createdAt}
                                ticketNumber={ticket.ticketNumber}
                                className={index > 0 ? "border-t border-gray-200" : ""}
                            />
                        ))
                    ) : (
                        <div className="flex flex-col items-center justify-center py-16 gap-6 w-full text-center">
                                <TicketIcon
                                    className="w-24 h-24 md:w-48 md:h-48 text-primary"
                                    style={{
                                            fill: "rgb(233, 213, 255)",
                                            stroke: "currentColor",
                                            strokeWidth: 1.5,
                                    }}
                                />
                                <h2 className="font-semibold text-2xl leading-8 text-black">
                                        Aucun ticket
                                </h2>
                                <p className="font-normal text-lg leading-8 text-gray-500">
                                        Vous n&apos;avez aucun ticket généré.
                                </p>
                        </div>
                    )}
            </div>
        );
};
TicketsContainer.propTypes = {
        mdbTickets: PropTypes.arrayOf(
            PropTypes.shape({
                    _id: PropTypes.string.isRequired,
                    ticketTitle: PropTypes.string.isRequired,
                    ticketDescription: PropTypes.string,
                    ticketStatus: PropTypes.string,
                    createdAt: PropTypes.string,
                    ticketNumber: PropTypes.string,
            })
        ),
};

export default TicketsContainer;

import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import axios from "axios";
import { toast } from "react-toastify";

export default function EditMessageModal({
  isOpen,
  closeModal,
  message,
  setMessages,
  setMessage,
  messages,
}) {
  const modalRef = useRef(null);
  const [id, setId] = useState(null);
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      closeModal();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  useEffect(() => {
    if (message && messages.length > 0) {
      const msg = messages.find((msg) => msg.message === message);
      setId(msg._id);
    }
  }, []);

  const handleChange = (e) => {
    const previousMessages = [...messages];
    const index = previousMessages.findIndex((msg) => msg._id === id);
    previousMessages[index].message = e.target.value;
    setMessages(previousMessages);
  };

  const handleUpdate = async () => {
    const message = messages.find((msg) => msg._id === id);
    delete message.replyMessage;
    const response = await axios.put("/messages/" + id, message);
    if (response.status === 200) {
      toast.success("Message mis à jour avec succès !");
    } else {
      toast.error("Erreur lors de la mise à jour du message");
    }
  };

  return (
    isOpen && (
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.3, ease: "easeOut" }}
        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30"
        onClick={handleClickOutside}
      >
        <div
          ref={modalRef}
          className="w-full max-w-md bg-white rounded-lg shadow-lg p-6"
          onClick={(e) => e.stopPropagation()}
        >
          <h2 className="text-lg font-bold mb-4">Modifier le message</h2>
          <textarea
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
            rows="4"
            value={message}
            onChange={handleChange} // Met à jour l'état local du message
          />
          <div className="flex justify-end mt-4">
            <button
              className="px-4 py-2 bg-primary text-white rounded-md hover:bg-opacity-90 transition"
              onClick={handleUpdate}
            >
              Valider
            </button>
          </div>
        </div>
      </motion.div>
    )
  );
}

EditMessageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired, // Message à modifier
  setMessage: PropTypes.func.isRequired, // Fonction pour mettre à jour le message
  messages: PropTypes.array.isRequired, // Liste des messages
  setMessages: PropTypes.func.isRequired, // Fonction pour mettre à jour la liste des messages
};

// eslint-disable-next-line no-unused-vars
import React from "react";
import { Partytown } from '@builder.io/partytown/react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Auth from "./pages/admin/Auth";
import Home from "./pages/client/Home";
import Contact from "./pages/client/Contact";
import Login from "./pages/client/Login";
import Messages from "./pages/client/Messages";
import TicketDetail from "./pages/client/TicketDetail";
import ClientLayout from "./components/layout/ClientLayout";
import AdminLayout from "./components/layout/AdminLayout";
import * as Sentry from "@sentry/react";
import Logout from "@/pages/client/Logout.jsx";
import config from "@/config/index.js";

Sentry.init({
  dsn: "https://8530b412d78a1b3b6c2193c30be2f2a2@o4508291057254400.ingest.de.sentry.io/4508412283715664",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  const clientRoutes = [
    { path: "/", component: Home, isProtected: true },
    { path: "/support", component: Contact, isProtected: true },
    { path: "/login", component: Login, isProtected: false },
    { path: "/messages", component: Messages, isProtected: true },
    { path: "/ticket-detail/:id", component: TicketDetail, isProtected: true },
    { path: "/logout", component: Logout, isProtected: true },
  ];

  const adminRoutes = [
    { path: "/admin/auth", component: Auth, isProtected: true },
  ];

  const BuildAdminLayout = (C, props) => {
    return <AdminLayout Component={C} props={props} />;
  };

  const BuildClientLayout = (C, props) => {
    return <ClientLayout Component={C} props={props} />;
  };

  return (
    <Router>
      <Routes>
        {clientRoutes.map(({ path, component, props, isProtected }) => (
          <Route
            key={path}
            path={path}
            element={BuildClientLayout(component, { ...props, isProtected })}
          />
        ))}
        {adminRoutes.map(({ path, component, props, isProtected }) => (
          <Route
            key={path}
            path={path}
            element={BuildAdminLayout(component, props, isProtected)}
          />
        ))}
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition="Bounce"
      />
      <Partytown debug={config.env === "development"} forward={['dataLayer.push']} />
    </Router>
  );
}

export default App;

import { useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { ArrowUturnLeftIcon as ArrowUturnLeftSolid } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import axios from "axios";
import dayjs from "dayjs";
import MessageModal from "@/components/MessageModal.jsx";
import { toast } from "react-toastify";
import {checkUser, parseStatus} from "@/helpers/functions.js";
import EditTicketModal from "../../components/EditTicketModal";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";

export default function TicketDetail() {
  const { id } = useParams(); // Récupère l'ID du ticket dans l'URL
  const [ticket, setTicket] = useState(null);
  const [canSee, setCanSee] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleSave = async (updatedTicket) => {
    try {
      const response = await axios.put(`/tickets/${id}`, updatedTicket, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        toast.success("Ticket mis à jour avec succès !");
        fetchTicket(id); // Recharger les données du ticket
      }
    } catch (error) {
        console.error(error);
      toast.error("Erreur lors de la mise à jour du ticket.");
    }
  };

  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsModalOpen(false);
      setSelectedConversation(null);
    }, 300);
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const fetchTicket = async (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const response = await axios.get(`/tickets/${id}`, {
        headers: {
          accept: "application/json",
        },
      });
      if (response.status === 200) {
        const data = await response.data;

        if ((user && user.role === "admin") || user.role === "superadmin") {
          setCanSee(true);
        }

        if (user.email === data.clientEmail) {
          setCanSee(true);
        }

        if (user && user.role === "user" && data.clientName === user.name) {
          setCanSee(true);
        }

        if (user && user.role === "user" && data.clientEmail === user.email) {
          setCanSee(true);
        }

        setTicket(data);

        if (!canSee) {
          //toast.error("Vous n'êtes pas autorisé à voir ce ticket");
        }
      } else {
        setTicket(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchConversationFromTicket = async (ticketId) => {
    const response = await axios.get(`/conversations/${ticketId}/from-ticket`);

    if (response.status === 201 || response.status === 200) {
      setSelectedConversation(response.data);
    }
  };

  const fetchMessages = async (conversationId) => {
    await axios
      .get(`/messages/${conversationId}`)
      .then((response) => {
        setMessages(response.data);
      })
      .catch(() => {
        toast.error(
          "Erreur lors de la récupération des messages de la conversation sélectionnée."
        );
      });
  };

  useEffect(() => {
    if (ticket?._id) {
      fetchConversationFromTicket(ticket?._id).then((r) => r);
    }
  }, [ticket]);

  useEffect(() => {
    if (selectedConversation) {
      fetchMessages(selectedConversation._id).then((r) => r);
    }
  }, [selectedConversation]);

  useEffect(() => {
    fetchTicket(id).then((r) => r);
  }, [id]);

  useEffect(() => {
    setCurrentUser(checkUser);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.5 }}
    >
      {ticket && canSee ? (
        <section className="py-24 relative">
          <div className="w-full max-w-7xl px-4 md:px-5 lg:px-6 mx-auto">
            <Link
              to="/"
              className="absolute top-8 left-8 flex items-center gap-2 text-primary hover:text-accent text-lg font-medium transition duration-300 underline underline-offset-4"
            >
              <ArrowUturnLeftSolid className="w-4 h-4" /> Retour
            </Link>

            <h2 className="font-bold text-5xl leading-tight text-black text-center">
              Détail du ticket
            </h2>
            <p className="mt-6 font-medium text-xl leading-9 text-gray-500 mb-14 text-center">
              ID Ticket :{" "}
              <span className="text-body">{ticket.ticketNumber}</span>
            </p>
            <div className="main-box border border-gray-200 rounded-2xl p-8 max-w-2xl max-lg:mx-auto lg:max-w-full">
              <div className="flex flex-col space-y-6">
                <div className="date">
                  <p className="font-medium text-xl leading-8 text-body">
                    Date de création :{" "}
                    <span className="text-primary">
                      {dayjs(ticket.createdAt).format("dddd MM/YYYY")}
                    </span>
                  </p>
                </div>

                <div className="status">
                  <p className="font-medium text-xl leading-8 text-body">
                    Statut :{" "}
                    <span className="text-primary">{parseStatus(ticket.ticketStatus)}</span>
                  </p>
                </div>

                <div className="priority">
                  <p className="font-medium text-xl leading-8 text-body">
                    Priorité :{" "}
                    <span className="text-primary">
                      {parseStatus(ticket.ticketPriority)}
                    </span>
                  </p>
                </div>

                <div className="type">
                  <p className="font-medium text-xl leading-8 text-body">
                    Ticket type :{" "}
                    <span className="text-primary">{parseStatus(ticket.type)}</span>
                  </p>
                </div>
                <hr />

                <div className="title">
                  <p className="font-medium text-xl leading-8 text-body">
                    {ticket.ticketTitle}
                  </p>
                </div>

                <div className="description">
                  <p className="font-normal text-lg leading-7 text-gray-600">
                    {ticket.ticketDescription}
                  </p>
                </div>

                <hr />

                <div className="client pb-8">
                  <h2 className={"text-xl pb-8"}>Détails client</h2>
                  <div>
                    <ul className="space-y-4">
                      <li>Nom et prénom: {ticket.clientName}</li>
                      <li>Email client: {ticket.clientEmail}</li>
                      <li>Numéro de téléphone: {ticket.clientPhone}</li>
                    </ul>
                  </div>
                </div>

                <div className="actions">
                  <h2 className={"text-xl pb-8"}>Gestion de mon ticket</h2>
                  <div className={"flex gap-3 "}>
                    <button
                      className={
                        "p-2 bg-validation hover:bg-validationHover rounded-md text-white transition duration-300"
                      }
                      data-umami-event="client-message-modal-open"
                      data-umami-event-ticket={ticket._id}
                      data-umami-event-tickettitle={ticket.ticketTitle}
                      onClick={() => {
                        setIsModalOpen(true);
                        fetchConversationFromTicket(ticket._id);
                      }}
                    >
                       Messagerie
                    </button>
                    <button
                      className="p-2 bg-accent hover:bg-accentHover rounded-md text-white transition duration-300"
                      onClick={() => setIsEditModalOpen(true)}
                      data-umami-event="client-edit-ticket-modal"
                      data-umami-event-ticket={ticket._id}
                      data-umami-event-tickettitle={ticket.ticketTitle}
                    >
                      Modifier le ticket
                    </button>
                    <EditTicketModal
                      isOpen={isEditModalOpen}
                      onClose={() => setIsEditModalOpen(false)}
                      ticket={ticket}
                      onSave={handleSave}
                    />
                    {ticket.ticketStatus !== "closed" && (
                        <button
                            className="p-2 bg-alert hover:bg-alertHover rounded-md text-white transition duration-300"
                            onClick={() => setIsDeleteModalOpen(true)}
                            data-umami-event="client-close-ticket-modal"
                            data-umami-event-ticket={ticket._id}
                            data-umami-event-tickettitle={ticket.ticketTitle}
                        >
                          Fermer le ticket
                        </button>
                    )}
                    <ConfirmDeleteModal
                        ticket={ticket}
                        isOpen={isDeleteModalOpen}
                        onClose={() => setIsDeleteModalOpen(false)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MessageModal
            closeModal={closeModal}
            isModalOpen={isModalOpen}
            isClosing={isClosing}
            selectedConversation={selectedConversation}
            messages={messages}
            message={message}
            setMessage={setMessage}
            handleFileChange={handleFileChange}
            currentUser={currentUser}
            file={file}
            setMessages={setMessages}
            setFile={setFile}
          />
        </section>
      ) : (
        // Section pour afficher si le ticket est introuvable
        <section className="py-24 relative">
          <div className="w-full max-w-7xl px-4 md:px-5 lg:px-6 mx-auto">
            <h2 className="font-bold text-5xl leading-tight text-black text-center">
              Ticket introuvable
            </h2>
            <p className="mt-6 font-medium text-xl leading-9 text-gray-500 mb-14 text-center">
              Le ticket que vous recherchez n&apos;existe pas ou a été supprimé.
            </p>
            <div className="main-box border border-gray-200 rounded-2xl p-8 max-w-2xl max-lg:mx-auto lg:max-w-full text-center">
              <p className="font-normal text-lg leading-7 text-gray-600 mb-8">
                Veuillez vérifier l&apos;identifiant du ticket ou en créer un
                nouveau si nécessaire.
              </p>
              <div className="flex justify-center space-x-4">
                <Link to="/">
                  <button className="rounded-full py-3 px-8 font-semibold text-lg leading-7 text-white bg-primary shadow-md transition-all duration-300 hover:bg-primaryHover">
                    Créer un ticket
                  </button>
                </Link>
                <Link to="/support">
                  <button className="rounded-full py-3 px-8 font-semibold text-lg leading-7 text-white bg-secondary shadow-md transition-all duration-300 hover:bg-secondaryHover">
                    Nous contacter
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      )}
    </motion.div>
  );
}

import {useEffect, useState} from "react"
import {checkUser, handleUmamiEvent} from "@/helpers/functions.js";

export default function Logout() {
        const [countdown, setCountdown] = useState(3);

        useEffect(() => {
                handleUmamiEvent("L'utilisateur se déconnecte de son compte", {...checkUser()});
                const interval = setInterval(() => {
                        setCountdown((prev) => prev - 1);
                }, 1000);

                const timer = setTimeout(() => {
                        localStorage.removeItem("token");
                        localStorage.removeItem("user");
                        window.location.href = "/login";
                }, 3000);

                return () => {
                        clearTimeout(timer);
                        clearInterval(interval);
                };
        }, [])
        return <div className="flex min-h-screen w-full flex-col items-center justify-center bg-gray-100 p-4">
                <div className="md w-full text-center">

                        {/* Heading */}
                        <h1 className="text-3xl font-bold text-gray-800 mb-4">
                                Déconnexion en cours...
                        </h1>

                        {/* Subheading */}
                        <p className="text-gray-600 mb-8">
                                Patientez un petit moment que nous vous déconnectons de votre session.
                        </p>

                        {/* Purple Animated Illustration */}
                        <div className={"mx-auto w-full flex justify-center"}>
                                <div className="relative mx-auto h-40 w-40 mb-10">
                                        <svg
                                            className="animate-pulse"
                                            width="160"
                                            height="160"
                                            viewBox="0 0 200 200"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                                {/* A decorative purple gradient background shape */}
                                                <defs>
                                                        <linearGradient id="purpleGradient" x1="0" y1="0" x2="1" y2="1">
                                                                <stop offset="0%" stopColor="#a78bfa"/>
                                                                {/* Purple-200 */}
                                                                <stop offset="100%" stopColor="#7c3aed"/>
                                                                {/* Purple-600 */}
                                                        </linearGradient>
                                                </defs>
                                                <circle
                                                    cx="100" cy="100" r="90"
                                                    fill="url(#purpleGradient)"
                                                    opacity="0.8"
                                                />
                                                {/* Inner shape that could represent progress */}
                                                <path
                                                    d="M100 30C138.66 30 170 61.34 170 100C170 138.66 138.66 170 100 170C61.34 170 30 138.66 30 100C30 61.34 61.34 30 100 30Z"
                                                    fill="white"
                                                    fillOpacity="0.6"
                                                />
                                                {/* Optional overlay details */}
                                                <text
                                                    x="50%"
                                                    y="50%"
                                                    dy=".3em"
                                                    textAnchor="middle"
                                                    className="font-bold"
                                                    fill="#7c3aed"
                                                    fontSize="24"
                                                >
                                                        {countdown}
                                                </text>
                                        </svg>
                                </div>
                        </div>

                        {/* Note or secondary action */}
                        <p className="text-sm text-gray-500">
                                Redirection vers la page de connexion en cours...
                        </p>
                </div>
        </div>;
}